<template>
  <div class="div_svg" id="div_svg">
    <svg
      id="svg"
      v-if="dialogform && log == 'main1'"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-300 -100 850 6042.276"
      :key="skey"
    >
      <title>{{ info_Component }}</title>
      <g id="main1" />
      <defs>
        <marker
          id="row"
          refX="1"
          refY="2"
          markerUnits="strokeWidth"
          markerWidth="10"
          markerHeight="10"
          orient="auto"
        >
          <path d="M 0 0 L 4 2 L 0 4 z" style="fill: red; stroke: none"></path>
        </marker>
        <pattern
          id="pattern-stripe"
          width="10"
          height="40"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="4"
            height="40"
            transform="translate(0,0)"
            fill="white"
          ></rect>
        </pattern>
      </defs>
    </svg>
    <svg
      id="svg2"
      v-if="dialogform && log == 'main2'"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-350 -100 1206.89 2500.276"
      :key="skey"
    >
      <title>{{ info_Component }}</title>
      <g id="main2" />
      <defs>
        <marker
          id="row"
          refX="1"
          refY="2"
          markerUnits="strokeWidth"
          markerWidth="10"
          markerHeight="10"
          orient="auto"
        >
          <path d="M 0 0 L 4 2 L 0 4 z" style="fill: red; stroke: none"></path>
        </marker>
        <pattern
          id="pattern-stripe"
          width="10"
          height="40"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="4"
            height="40"
            transform="translate(0,0)"
            fill="white"
          ></rect>
        </pattern>
      </defs>
    </svg>
    <barriersform
      :dialog="barrierdlg"
      @close="closeBarriers"
      state="E"
      :components_list="components_list"
      :formations_list="formations_list"
      :component="component"
      :failures_list="failure_fltlist"
      :downbarrier="downbarrier"
      :key="kb"
    />
    <cmpdetailsform
      :dialog="cmp_detaildlg"
      :list="components_details"
      @close="closeCmpdetails"
    />
  </div>
</template>

<script>
export default {
  components: {
    barriersform: () => import("../components/BarriersForm.vue"),
    cmpdetailsform: () => import("../components/ComponentsDetails.vue"),
  },
  props: {
    dialogform: Boolean,
    zoom: Number,
    log: String,
    components_list: [],
    components_other: Array,
    components_list_details: [],
    formations_list: [],
    failures_cmp_list: [],
    barriers: [],
    lst: [],
    ComponentShow: Function,
    failures_list: [],
    failures: [],
    heads: [],
    well: {},
    barrier_1: { type: Boolean, default: false },
  },
  data: () => ({
    unite: 0.2,
    top_head: 0,
    component: {},
    downbarrier: [],
    info_Component: "",
    barrierdlg: false,
    cmp_detaildlg: false,
    components_details: [],
    barriers_cmp: {},
    failure_fltlist: [],

    kb: 1000,
    skey: 100,
  }),
  created() {
    //this.skey++;
  },
  mounted() {
    //:barriers="barriers_cmp"
    if (this.dialogform) {
      this.addGauge();
      this.addxtree_top();
      this.addManifold();
      this.addxtree_left();
      this.addxtree_right();
      this.addxtree();
      this.addAnnulus();
      var main = document.getElementById(this.log);
      let obj = "scale(" + 1 + ")";
      let obj2 = "scale(" + 0.9 + ")";
      if (this.log == "main1") main.setAttribute("transform", obj);
      else main.setAttribute("transform", obj2);
      this.$emit("svg-ready", document.querySelector("#div_svg"));
    }
  },
  methods: {
    addimage(x, y, w, h, image) {
      var svgn = "http://www.w3.org/2000/svg";
      let adr = "";
      if (image == "Top_Gauge")
        adr = require("@/assets/well_parts/Top_Gauge.svg");
      else if (image == "Valve")
        adr = require("@/assets/well_parts/Valve_V.svg");
      else if (image == "ValveH")
        adr = require("@/assets/well_parts/Valve_H.svg");
      else if (image == "Manifold")
        adr = require("@/assets/well_parts/Manifold.svg");
      else if (image == "Annulus")
        adr = require("@/assets/well_parts/head.svg");
      else if (image == "TUB") adr = require("@/assets/well_parts/tubing.svg");
      else if (image == "TB30") adr = require("@/assets/well_parts/t_30.svg");
      else if (image == "TB31") adr = require("@/assets/well_parts/t_31.svg");
      else if (image == "TB32") adr = require("@/assets/well_parts/t_32.svg");
      else if (image == "CSGL") adr = require("@/assets/well_parts/csg_l.svg");
      else if (image == "CSGR") adr = require("@/assets/well_parts/csg_r.svg");
      else if (image == "TB50")
        adr = require("@/assets/well_parts/t_30.svg"); //a creer l'image de tb partie sup
      else if (image == "ENDV_R")
        adr = require("@/assets/well_parts/valve_end_r.svg");
      else if (image == "ENDV_L")
        adr = require("@/assets/well_parts/valve_end_l.svg");
      var g = document.createElementNS(svgn, "g");
      fetch(adr)
        .then((resp) => resp.text())
        .then(function (svgContent) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(svgContent, "image/svg+xml");
          const newSvg = doc.querySelector("svg");

          // 3. Configurez les attributs du nouvel élément SVG
          // newSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
          newSvg.setAttribute("x", x);
          newSvg.setAttribute("y", y);
          newSvg.setAttribute("width", w);
          newSvg.setAttribute("height", h);
          newSvg.setAttribute(
            "style",
            "shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
          );

          g.appendChild(newSvg);
        });

      return g;
    },

    getCSGlabel(order) {
      if (order == 1) return "1st CSG";
      else if (order == 2) return "2nd CSG";
      else if (order == 3) return "3rd CSG";
      else return order + "th CSG";
    },
    closeCmpdetails(index) {
      if (index) {
        this.component = index;
        let f = this.failures_cmp_list.filter(
          (i) => i.cmp_type_id == index.type_id
        );
        if (f.length > 0) {
          this.failure_fltlist = f;
        }
        this.barrierdlg = true;
      }
      this.cmp_detaildlg = false;
    },
    click: function (item) {
      if (this.log == "main2") this.ComponentShow(item.currentTarget.id); //component in well form
      //   else {
      //     //component in well failure
      //     let c = this.components_list.findIndex(
      //       (c) => c.code == item.currentTarget.id
      //     );
      //     this.component = this.components_list[c];

      //     let k = this.components_list.filter(
      //       (x) => x.compartment == item.currentTarget.id
      //     );
      //     if (k.length > 0) {
      //       //component block
      //       this.components_details = k;
      //       this.cmp_detaildlg = true;
      //     } else {
      //       //component single
      //       let cmp = this.components_other.findIndex(
      //         (c) => c.code == item.currentTarget.id
      //       );
      //       this.component = this.components_other[cmp];
      //       let f = this.failures_cmp_list.filter(
      //         (i) => i.cmp_type_id == this.component.type_id
      //       );
      //       if (f.length > 0) {
      //         this.failure_fltlist = f;
      //       }
      //       this.kb++;
      //       this.barrierdlg = true;
      //     }
      //   }
    },
    addGauge() {
      if (this.well.gauge) {
        var main = document.getElementById(this.log);

        main.appendChild(this.addimage(80, -50, 100, 100, "Top_Gauge"));
        main.appendChild(
          this.addCircle(
            123,
            18,
            this.well.gauge.id,
            this.well.gauge.code,
            this.well.gauge.label
          )
        );
      }
    },
    addxtree_top() {
      if (this.well.xtree_top) {
        let gauge = 0;
        var main = document.getElementById(this.log);
        if (this.well.gauge) gauge = 46;
        for (let i = 0; i < this.well.xtree_top.length; i++) {
          main.appendChild(
            this.addimage(75, gauge + i * 139, 97, 142, "Valve")
          );
          main.appendChild(
            this.addCircle(
              123,
              gauge + i * 139 + 70,
              this.well.xtree_top[i].id,
              this.well.xtree_top[i].code,
              this.well.xtree_top[i].label
            )
          );
        }
      }
    },
    addManifold() {
      if (this.well.Manifold) {
        let g = 0;
        let topValve = 0;
        var main = document.getElementById(this.log);
        if (this.well.gauge) g = 46;
        if (this.well.xtree_top) {
          topValve = this.well.xtree_top.length * 139;
        }
        if (topValve != 0)
          main.appendChild(
            this.addimage(82, g + topValve + 4, 80, 80, "Manifold")
          );
      }
    },
    addxtree_left() {
      if (this.well.xtree_left) {
        var main = document.getElementById(this.log);
        let g = 0;
        let topValve = 0;
        if (this.well.xtree_top) {
          topValve = this.well.xtree_top.length * 139;
        }
        if (this.well.gauge) g = 46;
        //let wl = this.well.xtree_left.components.sort(
        //  (a, b) => b.code - a.code
        // );
        for (
          let i = 0;
          i <
          this.well.xtree_left.components.sort((a, b) => a.code - b.code)
            .length;
          i++
        ) {
          main.appendChild(
            this.addimage(
              80 - (i + 1) * 99,
              g + topValve + 2,
              110,
              75,
              "ValveH"
            )
          );
          main.appendChild(
            this.addCircle(
              30 - i * 99,
              g + topValve + 38,
              this.well.xtree_left.components[i].id,
              this.well.xtree_left.components[i].code,
              this.well.xtree_left.components[i].label
            )
          );
        }
      }
    },
    addxtree_right() {
      if (this.well.xtree_right) {
        var main = document.getElementById(this.log);
        let g = 0;
        let topValve = 0;
        if (this.well.xtree_top) {
          topValve = this.well.xtree_top.length * 139;
        }
        if (this.well.gauge) g = 46;
        for (
          let i = 0;
          i <
          this.well.xtree_right.components.sort((a, b) => a.code - b.code)
            .length;
          i++
        ) {
          main.appendChild(
            this.addimage(160 + i * 98, g + topValve + 2, 110, 75, "ValveH")
          );
          main.appendChild(
            this.addCircle(
              217 + i * 98,
              g + topValve + 38,
              this.well.xtree_right.components[i].id,
              this.well.xtree_right.components[i].code,
              this.well.xtree_right.components[i].label
            )
          );
        }
      }
    },

    addxtree() {
      if (this.well.xtree) {
        var main = document.getElementById(this.log);
        let g = 0;
        let topValve = 0;
        let manifold = 0;
        if (this.well.gauge) g = 46;
        if (this.well.Manifold) manifold = 75;
        if (this.well.xtree_top) {
          topValve = this.well.xtree_top.length * 139;
        }
        for (let i = 0; i < this.well.xtree.length; i++) {
          main.appendChild(
            this.addimage(
              75,
              3 + manifold + g + topValve + i * 139,
              97,
              142,
              "Valve"
            )
          );
          main.appendChild(
            this.addCircle(
              124,
              74 + manifold + g + topValve + i * 139,
              this.well.xtree[i].id,
              this.well.xtree[i].code,
              this.well.xtree[i].label
            )
          );
        }
      }
    },
    addAnnulus() {
      if (this.well.Annulus) {
        var main = document.getElementById(this.log);
        let g = 0;
        let topValve = 0;
        let manifold = 0;
        let tophead = 0;
        let top = 0;
        if (this.well.gauge) g = 46;
        if (this.well.Manifold) manifold = 75;
        if (this.well.xtree) tophead = this.well.xtree.length * 139;
        if (this.well.xtree_top) {
          topValve = this.well.xtree_top.length * 139;
        }
        this.well.Annulus.sort((a, b) => a.csg_order - b.csg_order).forEach(
          (x) => {
            main.appendChild(
              this.addimage(
                24,
                4 + tophead + topValve + manifold + g + (x.csg_order - 1) * 156,
                200,
                158,
                "Annulus"
              )
            );
            //csg_TB
            if (this.well.csgs) {
              let ind = this.well.csgs.findIndex((i) => i.csg_order == 0);
              if (x.csg_order == 1 && ind >= 0) {
                let left = 30;
                if (this.well.csgs[ind].orientation == "R") left = 200;
                main.appendChild(
                  this.addCircle(
                    left,
                    20 +
                      tophead +
                      topValve +
                      manifold +
                      g +
                      (x.csg_order - 1) * 156,
                    this.well.csgs[ind].id,
                    this.well.csgs[ind].code,
                    this.well.csgs[ind].label
                  )
                );
              }
              let ind2 = this.well.csgs.findIndex(
                (i) => i.csg_order == x.csg_order
              );
              if (ind2 >= 0) {
                let left2 = 30;
                if (this.well.csgs[ind2].orientation == "R") left2 = 200;
                main.appendChild(
                  this.addCircle(
                    left2,
                    140 +
                      tophead +
                      topValve +
                      manifold +
                      g +
                      (x.csg_order - 1) * 156,
                    this.well.csgs[ind2].id,
                    this.well.csgs[ind2].code,
                    this.well.csgs[ind2].label
                  )
                );
              }
            }
            //
            top = 4 + tophead + topValve + manifold + g + x.csg_order * 156;
            let l = 0;
            let r = 0;
            let pos_r = 0;
            let pos_l = -35;
            x.components
              .sort((a, b) => a.type_id - b.type_id)
              .sort((a, b) => a.code - b.code)
              .forEach((y) => {
                //valves head
                if (y.type_id == 95) {
                  if (y.orientation == "R") {
                    main.appendChild(
                      this.addimage(
                        185 + l * 95,
                        50 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        110,
                        75,
                        "ValveH"
                      )
                    );
                    main.appendChild(
                      this.addCircle(
                        240 + l * 99,
                        84 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        y.id,
                        y.code,
                        y.label
                      )
                    );
                    l = l + 1;
                    pos_r = pos_r + 1;
                  } else {
                    main.appendChild(
                      this.addimage(
                        55 - (r + 1) * 98,
                        50 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        110,
                        75,
                        "ValveH"
                      )
                    );
                    //
                    main.appendChild(
                      this.addCircle(
                        10 - r * 98,
                        84 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        y.id,
                        y.code,
                        y.label
                      )
                    );
                    r = r + 1;
                    pos_l = 55 - (r + 1) * 105;
                  }
                }

                ///bonnets
                else if (y.type_id == 75) {
                  if (y.orientation == "R") {
                    main.appendChild(
                      this.addimage(
                        185 + l * 98,
                        50 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        80,
                        74,
                        "ENDV_R"
                      )
                    );

                    main.appendChild(
                      this.addCircle(
                        290 + pos_r * 105,
                        84 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        y.id,
                        y.code,
                        y.label
                      )
                    );
                  } else {
                    main.appendChild(
                      this.addimage(
                        pos_l + 40,
                        50 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        80,
                        74,
                        "ENDV_L"
                      )
                    );
                    main.appendChild(
                      this.addCircle(
                        pos_l + 15,
                        84 +
                          tophead +
                          topValve +
                          manifold +
                          g +
                          (x.csg_order - 1) * 156,
                        y.id,
                        y.code,
                        y.label
                      )
                    );
                  }
                  //----
                }
                //hangers
                else if (y.type_id == 83) {
                  main.appendChild(
                    this.addCircle(
                      200,
                      tophead +
                        topValve +
                        manifold +
                        g +
                        20 +
                        (x.csg_order - 1) * 156,
                      y.id,
                      y.code,
                      y.label
                    )
                  );
                } else if (y.type_id == 85) {
                  main.appendChild(
                    this.addCircle(
                      100,
                      tophead +
                        topValve +
                        manifold +
                        g +
                        80 +
                        (x.csg_order - 1) * 156,
                      y.id,
                      y.code,
                      y.label
                    )
                  );
                }
              });
          }
        );

        top = top + 50;
        this.top_head = top;
        if (this.well.tubulars) this.unite = 0.15; // this.well.tubulars.depth / 1000;

        //add csgs
        this.well.Annulus.forEach((x) => {
          //topcement right
          if (x.topcement != null) {
            main.appendChild(
              this.addSurface(
                10 - 70 * (x.csg_order - 1),
                top + x.topcement * this.unite + 8,
                this.unite * (x.depth - x.topcement),
                40,
                "Top cement " + (x.csg_order + 9).toString(36).toUpperCase(),
                "gray"
              )
            );
            main.appendChild(
              this.addSurface(
                128 + 70 * x.csg_order,
                top + x.topcement * this.unite + 8,
                this.unite * (x.depth - x.topcement),
                40,
                "Top cement " + (x.csg_order + 9).toString(36).toUpperCase(),
                "gray"
              )
            );

            if (this.well.cement_barriers) {
              this.well.cement_barriers
                .filter((c) => c.csg_order == x.csg_order + 1)
                .forEach((f) => {
                  //left cement test
                  main.appendChild(
                    this.addSurface(
                      10 - 70 * (x.csg_order - 1),
                      top + f.depthfrom * this.unite + 8,
                      f.depthto * this.unite,
                      40,
                      "",
                      "url(#pattern-stripe)"
                    )
                  );
                  //right cement test
                  main.appendChild(
                    this.addSurface(
                      128 + 70 * x.csg_order,
                      top + f.depthfrom * this.unite + 8,
                      f.depthto * this.unite,
                      40,
                      "",
                      "url(#pattern-stripe)"
                    )
                  );
                });
            }
          }

          ///add csgs (left)

          main.appendChild(
            this.addSurface(
              120 - 70 * x.csg_order,
              top + 8,
              this.unite * x.depth,
              30,
              "CSG " + x.csg_order,
              "blue"
            )
          );
          //add annulus(right)
          main.appendChild(
            this.addSurface(
              98 + 70 * x.csg_order,
              top + 8,
              this.unite * x.depth,
              30,
              "CSG " + x.csg_order,
              "blue"
            )
          );

          if (this.well.corrosion_barriers) {
            this.well.corrosion_barriers
              .filter((c) => c.csg_order == x.csg_order + 1)
              .forEach((f) => {
                //corrosions left
                main.appendChild(
                  this.addcorrosion(
                    120 - 70 * x.csg_order,
                    top + f.depthfrom * this.unite,
                    (f.depthto - f.depthfrom) * this.unite,
                    30,
                    "red",
                    "",
                    ""
                  )
                );
                //corrosions right
                main.appendChild(
                  this.addcorrosion(
                    98 + 70 * x.csg_order,
                    top + f.depthfrom * this.unite,
                    (f.depthto - f.depthfrom) * this.unite,
                    30,
                    "red",
                    "",
                    ""
                  )
                );
              });
          }
          //CSG code
          main.appendChild(
            this.addCircle(
              115 + 70 * x.csg_order,
              top + 50,
              this.getCSG_code(x.csg_order),
              this.getCSG_code(x.csg_order),
              this.getCSGlabel(x.csg_order)
            )
          );
          //annulus code
          main.appendChild(
            this.addCircle(
              80 + 70 * x.csg_order,
              top - 20,
              this.getAnnulus_code(x.csg_order),
              this.getAnnulus_code(x.csg_order),
              "Annulus " + (x.csg_order + 9).toString(36).toUpperCase()
            )
          );
        });
        this.downbarrier = [];
        //add tubing
        if (this.well.tubulars) {
          main.appendChild(
            this.addcylinder(
              105,
              top + 8,
              this.well.tubulars.depth * this.unite,
              "gray"
            )
          );
          // main.appendChild(this.addcylinder(110, top + 300, 800, "black"));
          if (this.well.TUBING32) {
            let d = top + 160 + (this.well.tubulars.depth * this.unite) / 3;
            //DHSV
            main.appendChild(
              this.addSurface(105, d - 50, 30, 32, "DHSV", "blue")
            );
            //    main.appendChild(this.addimage(100, top + 120, 50, 80, "TB50"));
            main.appendChild(this.addCircle(120, d, 52, 52, "DHSV"));
            main.appendChild(
              this.addCircle(120, top + 100, 5001, 5001, "TUBING Above")
            );
            main.appendChild(
              this.addCircle(120, d + 60, 5002, 5002, "TUBING Bellow")
            );
            this.downbarrier.push("52");
            this.downbarrier.push("5001");
          } else {
            this.downbarrier.push("50");
            main.appendChild(this.addCircle(120, top + 200, 50, 50, "TBG"));
          }
        }

        //add formations
        if (this.well.formations && this.log != "main2") {
          this.well.formations
            .filter((f) => f.depth)
            .forEach((f) => {
              main.appendChild(
                this.addformation(
                  -300,
                  6 + top + f.depthfrom * this.unite,
                  f.depth * this.unite,
                  150,
                  f.formationname,
                  f.color
                )
              );
            });
        }
        //
        if (this.failures) {
          let f = this.failures.filter((x) => x.formation_id);
          if (f.length > 0) {
            f.forEach((i) => {
              main.appendChild(this.add_route(i.cmp_code, i.formation_id));
            });
          }
        }
        if (this.failures) {
          let f = this.failures.filter((x) => x.depth > 0);
          if (f.length > 0) {
            f.forEach((i) => {
              main.appendChild(this.add_route_depth(i.cmp_code, i.depth));
            });
          }
        }
      }
    },
    getAnnulus_code(order) {
      if (order == 1) return "51";
      else return 52 + order * 2;
    },
    getCSG_code(order) {
      return 53 + order * 2;
    },
    closeBarriers(item) {
      if (item) {
        this.$emit("add", item);
        this.skey++;
      }
      this.barrierdlg = false;
    },
    get_color(taux) {
      let color = "green";
      if (taux <= 85 && taux >= 70) color = "orange";
      else if (taux <= 69 && taux >= 50) color = "brown";
      else if (taux <= 49 && taux >= 17) color = "red";
      else if (taux <= 16 && taux >= 0) color = "black";
      else color = "#556B2F";
      return color;
    },

    addCircle(x, y, id, wc_id, label, ry) {
      //left, top,component_id
      let issue = "";
      let r = 25;
      if (ry) r = ry;
      let color = "green";
      let v = 100;

      if (this.failures) {
        let ki = this.components_list.filter((x) => x.compartment == wc_id);
        //component compose
        if (ki.length > 0) {
          ki.forEach((fki) => {
            let i = this.failures.findIndex((x) => x.cmp_code == fki.code);
            if (i >= 0) {
              if (this.failures[i].failure_coef < v)
                v = this.failures[i].failure_coef;
              issue = this.failures[i].issue;
              label = fki.label;
            }
          });
          //component simple
        } else {
          let k = this.failures.findIndex((x) => x.cmp_code == wc_id);
          if (k >= 0) {
            v = this.failures[k].failure_coef;
            issue = this.failures[k].issue;
          }
        }
      }

      if (v == 100) color = "green";
      else if (v == 50) color = "orange";
      else if (v == 0) color = "red";
      //100 green
      //50 yellow
      //0 red
      let scolor = "fill: " + color;
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");
      if (this.log == "main2") g.setAttribute("id", id);
      else g.setAttribute("id", wc_id);

      var circle = document.createElementNS(svgn, "circle");
      circle.setAttributeNS(null, "cx", x - 0.5);
      circle.setAttributeNS(null, "cy", y + 1);
      circle.setAttributeNS(null, "r", r);
      circle.setAttributeNS(null, "style", scolor);
      circle.setAttributeNS(null, "opacity", "0.4");
      var text = document.createElementNS(svgn, "text");
      text.setAttributeNS(null, "x", x);
      text.setAttributeNS(null, "y", y + 4);
      text.setAttributeNS(null, "fill", "#FFF");
      text.setAttributeNS(null, "font-family", "AvenirNext-Bold");
      text.setAttributeNS(null, "font-size", "20");
      text.setAttributeNS(null, "font-weight", "bold");
      text.setAttributeNS(null, "text-anchor", "middle");
      text.textContent = wc_id;
      this.info_Component = label;

      text.addEventListener(
        "mouseover",
        (e) => {
          this.info_Component = label + "  \r\n" + issue;

          e.target.style.cursor = "hand";
        },
        false
      );
      circle.addEventListener(
        "mouseout",
        (e) => {
          this.info_Component = "";
          e.target.style.cursor = "pointer";
        },
        false
      );
      circle.addEventListener(
        "mouseover",
        (e) => {
          this.info_Component = label + "  \r\n " + issue;
          //  e.target.style.fill = "blue";
          e.target.style.cursor = "hand";
        },
        false
      );

      g.addEventListener("click", this.click, false);

      g.appendChild(circle);
      g.appendChild(text);
      return g;
    },
    addSurface(x, y, h, w, label, color) {
      var svgn = "http://www.w3.org/2000/svg";
      var head = document.createElementNS(svgn, "rect");
      head.setAttributeNS(null, "x", x);
      head.setAttributeNS(null, "y", y);
      head.setAttributeNS(null, "width", w);
      head.setAttributeNS(null, "height", h);
      head.setAttributeNS(null, "fill", color); //

      head.addEventListener(
        "mouseout",
        () => {
          this.info_Component = "";
        },
        false
      );
      head.addEventListener(
        "mouseover",
        () => {
          this.info_Component = label;
        },
        false
      );
      return head;
    },
    addformation(x, y, h, w, label, color) {
      var svgn = "http://www.w3.org/2000/svg";
      var head = document.createElementNS(svgn, "rect");
      var g = document.createElementNS(svgn, "g");
      head.setAttributeNS(null, "x", x);
      head.setAttributeNS(null, "y", y);
      head.setAttributeNS(null, "width", w);
      head.setAttributeNS(null, "height", h);
      head.setAttributeNS(null, "fill", color); //

      var text = document.createElementNS(svgn, "text");
      text.setAttributeNS(null, "x", x);
      text.setAttributeNS(null, "y", y + 20);
      text.setAttributeNS(null, "fill", "blue");
      text.setAttributeNS(null, "font-family", "AvenirNext-Bold");
      text.setAttributeNS(null, "font-size", "18");
      text.setAttributeNS(null, "text-anchor", "left");
      text.textContent = label;

      head.addEventListener(
        "mouseout",
        () => {
          this.info_Component = "";
        },
        false
      );
      head.addEventListener(
        "mouseover",
        () => {
          this.info_Component = label;
        },
        false
      );
      g.appendChild(head);
      g.appendChild(text);
      return g;
    },

    addcorrosion(x, y, h, w, color, str1, str2) {
      var svgn = "http://www.w3.org/2000/svg";

      var head = document.createElementNS(svgn, "g");
      var rct = document.createElementNS(svgn, "rect");
      rct.setAttributeNS(null, "x", x);
      rct.setAttributeNS(null, "y", y);
      rct.setAttributeNS(null, "width", w);
      rct.setAttributeNS(null, "height", h);
      rct.setAttributeNS(null, "fill", color);
      //
      var text = document.createElementNS(svgn, "text");
      text.setAttributeNS(null, "x", x + 50);
      text.setAttributeNS(null, "y", y + 30);
      text.setAttributeNS(null, "fill", "#FFF");
      text.setAttributeNS(null, "font-family", "AvenirNext-Bold");
      text.setAttributeNS(null, "font-size", "18");
      text.setAttributeNS(null, "text-anchor", "middle");
      text.textContent = str1;
      //
      var text2 = document.createElementNS(svgn, "text");
      text2.setAttributeNS(null, "x", x + 50);
      text2.setAttributeNS(null, "y", y + 60);
      text2.setAttributeNS(null, "fill", "#FFF");
      text2.setAttributeNS(null, "font-family", "AvenirNext-Bold");
      text2.setAttributeNS(null, "font-size", "18");
      text2.setAttributeNS(null, "font-weight", "bold");
      text2.setAttributeNS(null, "text-anchor", "middle");
      text2.textContent = str2;
      head.appendChild(rct);
      head.appendChild(text);
      head.appendChild(text2);

      return head;
    },

    addcylinder(left, top, height, color) {
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");
      var path = document.createElementNS(svgn, "path");
      let th = height * Math.cos((0.25 * Math.PI) / 2);
      let d =
        "M " +
        left +
        "," +
        top +
        " a 16,5.64 0,0,0 32 0 a 16,5.64 0,0,0 -32 0 l 0," +
        th +
        " a 16,5.64 0,0,0 32 0 l 0,-" +
        th;
      path.setAttributeNS(null, "fill", color);
      path.setAttributeNS(null, "stroke", "black");
      path.setAttributeNS(null, "d", d);
      g.appendChild(path);

      return g;
    },

    add_route(code, formation) {
      let order_csg = 0;
      if (code == 51) order_csg = 1;
      else if (code == 56) order_csg = 2;
      else if (code == 58) order_csg = 3;
      else if (code == 60) order_csg = 4;
      else if (code == 62) order_csg = 5;
      else if (code == 64) order_csg = 6;
      else if (code == 66) order_csg = 7;
      else if (code == 68) order_csg = 8;
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");

      if (this.well.Annulus) {
        let k = this.well.formations.filter(
          (x) => String(x.formation_id) == String(formation)
        );
        if (k.length > 0) {
          let depth =
            8 +
            this.top_head +
            k[0].depthto * this.unite -
            (k[0].depth * this.unite) / 2;

          let left_from = -200;
          let d =
            "M" +
            left_from +
            " " +
            depth +
            " L" +
            (85 + 68 * order_csg) +
            " " +
            depth +
            " L" +
            (85 + 68 * order_csg) +
            " " +
            (this.top_head + 10);

          var path = document.createElementNS(svgn, "path");
          var anim = document.createElementNS(svgn, "animate");
          path.setAttributeNS(null, "stroke", "red");
          path.setAttributeNS(null, "stroke-width", "6");
          path.setAttributeNS(null, "fill", "none");
          path.setAttributeNS(null, "stroke-dasharray", "10");
          // path.setAttributeNS(null, "marker-end", "url(#row)");
          path.setAttributeNS(null, "d", d);

          anim.setAttributeNS(null, "attributeName", "stroke-dashoffset");
          anim.setAttributeNS(null, "values", "100;0");
          anim.setAttributeNS(null, "dur", "3s");
          anim.setAttributeNS(null, "calcMode", "linear");
          anim.setAttributeNS(null, "repeatCount", "indefinite");
          path.appendChild(anim);
          g.appendChild(path);
        }
      }
      return g;
    },
    add_route_depth(code, depth_to) {
      let order_csg = 0;
      if (code == 51) order_csg = 1;
      else if (code == 56) order_csg = 2;
      else if (code == 58) order_csg = 3;
      else if (code == 60) order_csg = 4;
      else if (code == 62) order_csg = 5;
      else if (code == 64) order_csg = 6;
      else if (code == 66) order_csg = 7;
      else if (code == 68) order_csg = 8;
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");

      if (this.well.Annulus) {
        let depth = 12 + this.top_head + (depth_to * this.unite) / 2;

        let left_from = -200;
        let d =
          "M" +
          left_from +
          " " +
          depth +
          " L" +
          (85 + 68 * order_csg) +
          " " +
          depth +
          " L" +
          (85 + 68 * order_csg) +
          " " +
          (this.top_head + 10);

        var path = document.createElementNS(svgn, "path");
        var anim = document.createElementNS(svgn, "animate");
        path.setAttributeNS(null, "stroke", "red");
        path.setAttributeNS(null, "stroke-width", "6");
        path.setAttributeNS(null, "fill", "none");
        path.setAttributeNS(null, "stroke-dasharray", "10");
        // path.setAttributeNS(null, "marker-end", "url(#row)");
        path.setAttributeNS(null, "d", d);

        anim.setAttributeNS(null, "attributeName", "stroke-dashoffset");
        anim.setAttributeNS(null, "values", "100;0");
        anim.setAttributeNS(null, "dur", "3s");
        anim.setAttributeNS(null, "calcMode", "linear");
        anim.setAttributeNS(null, "repeatCount", "indefinite");
        path.appendChild(anim);
        g.appendChild(path);
      }
      return g;
    },
    draw_barrier_1_dhsv(points) {
      var svgn = "http://www.w3.org/2000/svg";
      var head = document.createElementNS(svgn, "polyline");
      if (this.barrier_1) {
        let p = "";
        points.split(";").forEach((px) => (p += " " + px));
        head.setAttributeNS(null, "points", p);
        head.setAttributeNS(null, "stroke-width", "4");
        head.setAttributeNS(null, "stroke", "red");
        head.setAttributeNS(null, "fill", "transparent");
      }
      return head;
    },
  },
};
</script>
<style scoped>
path,
circle {
  stroke: #ffffff;
  stroke-width: 4px;
  fill: none;
}
div_svg {
  margin: 4px, 4px;
  padding: 4px;
  width: 1200;
  background-color: null;
  width: 300px;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  border: none;
}
</style>
